import React from 'react'
import Helmet from 'react-helmet'
import {
  Span,
  Layout,
  Heading,
  Row,
  Column,
  Image,
  Section,
  Text,
} from 'components'
import Blog from 'images/blogs/blog-11.png'
import 'components/css/stories.css'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/stories/BenefitsOfGoingPaperless`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: '4 Benefits Of Going Paperless With Digipay',
  },
  {
    property: 'og:description',
    content: 'Alam mo bang paperless ang lahat ng transaction sa Digipay?',
  },
  {
    property: 'og:image',
    content: Blog,
  },
]

const ReasonsWhy = () => (
  <Layout>
    <Helmet title="4 Benefits Of Going Paperless With Digipay" meta={SITE_META}>
      <html lang="en" />
    </Helmet>

    <Row>
      <Column size="df:[100%] lg:[col-6]" py="sp4" px="sp3" m="auto">
        <Image width="100%" mb="sp3" src={Blog} />
        <Heading
          className="blog__title"
          mb="sp4"
          fontWeight="bold"
          textAlign="center"
        >
          4 BENEFITS OF GOING PAPERLESS WITH DIGIPAY
        </Heading>

        <Section mb="sp4">
          <Text>
            Digipay is <Span fontWeight="bold">digital</Span> for a reason. Mula
            pagsign-up hanggang sa pagtransact ng services, hindi mo kailangang
            magpa-photocopy to submit your requirements o magprint ng resibo
            para sa mga suki. Here are four (4) reasons why it’s fun to go
            paperless with Digipay:
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            GO, GO, GO GREEN!
          </Heading>
          <Text>
            Hindi si Ruffa Mae ang nagsabi niyan, kung hindi si Mother Earth.
            🌳🌳🌳 Sa panahon ngayon, we all need to do something for the
            environment. Maliit na bagay pero it makes a huge difference.
            Imagine all the trees we’re saving in every transaction. Sa Digipay,
            lahat ng resibo ay pwede mong matanggap via email or SMS.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            TIPIDITY AT ITS FINEST
          </Heading>
          <Text>
            Kung isa kang Digipay Agent, this will be your new favorite word--
            TIPIDITY. Tipid sa printer, tipid sa papel, at tipid sa ink. Bakit?
            Kasi hindi mo na kailangang bilhin ang mga ‘yan. Sa Digipay,
            cellphone at internet lang ang kailangan. That’s the best thing
            about this business. Walang gastos dahil ang gusto namin ay
            magkaroon ka ng extra income.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            NO KALAT? I LIKE IT A LOT!
          </Heading>
          <Text>
            Kung hindi paperless sa Digipay, siguradong patong-patong na ang
            papel sa drawer mo. Syempre ayaw natin ng dagdag kalat sa space,
            ‘diba? Buti na lang, automatic isine-send sa email ng Digipay Agent
            ang lahat ng successful transactions. Hindi naman masamang mag-adapt
            sa technology lalo na kung mas napagagaan nito ang ating buhay.
            Agree? Agree!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            EASY ACCESS KAYA NO STRESS
          </Heading>
          <Text>
            ‘Pag may nanghingi sa’yo ng resibo niya ng Meralco payment three (3)
            months ago, kakabahan ka ba? Basta Digipay Agent, confident ‘yan!
            Buksan mo lang ang email at madali mo na maa-access ang hinihinging
            receipt sa search bar. Imagine kung hahanapin mo pa sa sandamakmak
            na papel, dagdag stress pa ‘yan. Basta ‘wag ka lang magde-delete ng
            email, siguradong okay ka with Digipay.
          </Text>
          <Text>Hanggang sa muli, ka-Digipay!</Text>
        </Section>
      </Column>
    </Row>
  </Layout>
)

export default ReasonsWhy
